import React, { useEffect, useState } from "react";
import { Box, Grid, GridItem, Text, useToast } from "@chakra-ui/react";
import moment from "moment";
import Header from "../components/layout/Header";
import Stat from "../components/general/Stat";
import styled from "styled-components";
import Table from "../components/general/Table";
import Card from "../components/general/Card";
import LineCard, { LineValue } from "../components/general/LineCard";
import { useQuery } from "react-query";
import { getAccounts, getOrders, getStats, getCorrespondent } from "../api/api";
import { Account, Order, Stats } from "../api/types";
import { AppContext } from "../globals/appcontext";
import { useNavigate } from "react-router-dom";
import { getDates } from "../globals/utils";
import OrderDetails from "../components/page/OrderDetails";
import { isBrowser } from "react-device-detect";
import { APILinks } from "../globals/consts";

const HeaderStat = styled(GridItem)`
  margin-left: 1rem;
  ${isBrowser ? "margin: 2rem 0rem 3rem 1rem;" : ""}
`;

interface OmnibusDashboardProps {
  cardHeight: string;
  pageCol: number;
  halfBox: number;
  ordersTableRows: (string | moment.Moment)[][];
  colSpan: number;
}

const OmnibusDashboard = (props: OmnibusDashboardProps): React.ReactElement => {
  const appContext = React.useContext(AppContext);
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>();
  const [orderStats, setOrderStats] = useState<LineValue[]>([]);
  const toast = useToast();
  const navigate = useNavigate();

  const { data: correspondent } = useQuery("correspondent", () =>
    getCorrespondent()
  );

  const tradingCurrency = correspondent?.lct_currency ?? "USD";

  const ordersQuery = useQuery("orders", () =>
    getOrders({ status: "all", limit: 100 })
  );
  const accountsQuery = useQuery("accounts", () =>
    getAccounts({ firmAccounts: true })
  );
  const statsQuery = useQuery("stats", getStats);
  const stats = statsQuery.data || ({} as Stats);
  const { cardHeight, colSpan, halfBox, ordersTableRows, pageCol } = props;

  const isError = ordersQuery.isError || accountsQuery.isError;
  useEffect(() => {
    const err = ordersQuery.error || accountsQuery.error;
    // Only display error if appContext is valid
    if (isError && appContext?.correspondent?.ID)
      toast({
        title: "An error occurred fetching dashboard data",
        description: (err as Error).message,
        status: "error",
      });
  }, [isError]);

  useEffect(() => {
    if (!ordersQuery.data) return;
    const datesArray = getDates(moment().subtract(30, "d"), moment()).map((d) =>
      d.format("YYYY-MM-DD")
    );
    const dates = Object.fromEntries(datesArray.map((d) => [d, 0]));

    ordersQuery.data.forEach((order) => {
      const createdAt = order.created_at.format("YYYY-MM-DD");
      if (dates[createdAt]) {
        dates[createdAt] += 1;
      }
    });

    const stats = Object.keys(dates).map((date) => ({
      name: date,
      value: dates[date],
    }));
    setOrderStats(stats);
  }, [ordersQuery.data]);

  const mapAccountRows = (a: Account) => {
    const amount = (a.last_equity || 0).toString();
    return [a.account_number, a.id, amount];
  };

  const longAccount = accountsQuery.data
    ?.filter((a) => a.account_number?.endsWith("LO"))
    .map(mapAccountRows);
  const shortAccount = accountsQuery.data
    ?.filter((a) => a.account_number?.endsWith("SO"))
    .map(mapAccountRows);

  const accountCard = (title: string, account?: string[][]) => {
    return (
      <Card height="100%">
        <Text fontSize="xl" mb="1rem">
          {title}
        </Text>
        <Table
          hidePagination
          halfCard={true}
          title={title}
          isLoading={accountsQuery.isLoading}
          inCard
          headers={["Account #", "Account ID", "Amount"]}
          copyIndexes={[0, 1]}
          rightJustifyIndexes={[4, 5]}
          rows={account || []}
          totalRows={1}
          onValueClick={(rowIdx) => {
            navigate(`/accounts/${account?.[rowIdx][1]}`);
          }}
          noContentTitle="No account found"
          noContentLinkText="Learn more about Accounts"
          noContentLinkDestination={APILinks.accounts}
        />
      </Card>
    );
  };

  return (
    <Box>
      <OrderDetails
        isOpen={!!selectedOrder}
        onClose={() => setSelectedOrder(undefined)}
        order={selectedOrder}
        currency={tradingCurrency}
      />
      <Header title="Welcome Back!" />
      <Grid templateColumns={`repeat(${pageCol}, 1fr)`} gap="2.5rem" mt="1rem">
        <HeaderStat colSpan={colSpan}>
          <Stat
            change={stats.orders_count_week_change || 0}
            changeInfo="from last week"
            stat={stats.orders_count_week}
            statInfo="# Orders this week"
          />
        </HeaderStat>
        <HeaderStat colSpan={colSpan}>
          <Stat stat={stats.aum} isMoney statInfo="Assets Under Management" />
        </HeaderStat>
      </Grid>
      <Grid
        templateRows="repeat(2, 1fr)"
        templateColumns={`repeat(${pageCol}, 1fr)`}
        gap={4}
        mt="1rem"
      >
        <GridItem colSpan={halfBox} rowSpan={2}>
          <LineCard
            height={cardHeight}
            title="Orders"
            values={orderStats}
            noContentTitle="No orders in the last 30 days"
            noContentLinkText="Learn more about Orders"
            noContentLinkDestination={APILinks.orders}
          />
        </GridItem>
        <GridItem colSpan={halfBox} rowSpan={1}>
          {accountCard("Short Account", shortAccount)}
        </GridItem>
        <GridItem colSpan={halfBox} rowSpan={1}>
          {accountCard("Long Account", longAccount)}
        </GridItem>
        <GridItem colSpan={pageCol}>
          <Card height={cardHeight}>
            <Text fontSize="xl" mb="1rem">
              Recent Orders
            </Text>
            <Table
              isLoading={ordersQuery.isLoading}
              inCard
              headers={[
                "Account ID",
                "Order ID",
                "Side",
                "Symbol",
                "Quantity",
                "Total",
                "Status",
                "Updated At",
              ]}
              copyIndexes={[0, 1]}
              rightJustifyIndexes={[4, 5]}
              rowsPerPage={6}
              rows={ordersTableRows}
              totalRows={ordersTableRows.length}
              onValueClick={(rowIdx, colIdx) => {
                // Account ID on table was clicked
                if (colIdx === 0)
                  navigate(`/accounts/${ordersTableRows[rowIdx][0]}`);
              }}
              onRowClick={(idx) =>
                setSelectedOrder((ordersQuery.data || [])[idx])
              }
              noContentTitle="No recent orders available"
              noContentLinkText="Learn more about Orders"
              noContentLinkDestination={APILinks.orders}
            />
          </Card>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default OmnibusDashboard;
