import moment from "moment";

type EnumDictionary<T extends string | number, U> = {
  [K in T]: U;
};

// Used to map 'arbitrary' responses
// eslint-disable-next-line
export type Generic = { [key: string]: any };

/*
 * TYPES
 */

export enum Role {
  Superuser = "superuser",
  Developer = "developer",
  Operations = "operations",
  Support = "support",
}

export const RoleNames: EnumDictionary<string, string> = {
  [Role.Superuser]: "Super User",
  [Role.Developer]: "Developer (Admin)",
  [Role.Operations]: "Operations",
  [Role.Support]: "Support",
};

export type MFAStatus = "ENABLED" | "VERIFY_PHONE" | "PHONE_REQUIRED";

/*
 * REQUEST RESPONSES
 */

interface KeyValue {
  [index: string]: number | string;
}

export interface AccountContact {
  email_address: string;
  phone_number: string;
  street_address: string[];
  city: string;
  state: string;
  postal_code: string;
}

export interface AccountDisclosures {
  employment_status?: string;
  employer_name?: string;
  employer_address?: string;
  employment_position?: string;
  is_control_person: boolean;
  is_affiliated_exchange_or_finra: boolean;
  is_politically_exposed: boolean;
  immediate_family_exposed: boolean;
}

export interface Agreement {
  agreement: string;
  ip_address: string;
  signed_at: moment.Moment;
}

interface Identity {
  annual_income_max?: string;
  annual_income_min?: string;
  country_of_birth?: string;
  country_of_citizenship?: string;
  country_of_tax_residence: string;
  date_of_birth: string;
  family_name: string;
  funding_source: string[];
  given_name: string;
  liquid_net_worth_max?: string;
  liquid_net_worth_min?: string;
  tax_id?: string;
  tax_id_type?: string;
  total_net_worth_max?: string;
  total_net_worth_min?: string;
  extra: KeyValue;
}

export interface TrustedContact {
  city?: string;
  country?: string;
  email_address?: string;
  family_name: string;
  given_name: string;
  phone_number?: string;
  postal_code?: string;
  state?: string;
  street_address?: string[];
}

export interface Account {
  account_number: string;
  agreements: Agreement[];
  contact: AccountContact;
  created_at: moment.Moment;
  currency?: string;
  disclosures: AccountDisclosures;
  id: string;
  identity: Identity;
  last_equity?: number;
  status: string;
  crypto_status?: string;
  trusted_contact?: TrustedContact;
  documents: AccountDocument[];
  // Derived fields
  name?: string;
  email?: string;
}

export interface AccountDocument {
  document_type: string;
  document_sub_type: string;
  content: string;
  mime_type: string;
}

export interface TradingAccount {
  account_blocked: boolean;
  account_number: string;
  accrued_fees: string;
  adj_mr?: string;
  available_funds?: string;
  balance_asof: string;
  bod_dtbp: string;
  buying_power: string;
  cash: string;
  cash_transferable: string;
  cash_withdrawable: string;
  cash_withdrawable_margin: string;
  clearing_broker: string;
  cost_basis?: string;
  created_at: Date;
  crypto_market_value?: string;
  crypto_status: string;
  currency: string;
  day_transaction_credit?: string;
  day_transaction_debit?: string;
  daytrade_count: number;
  daytrading_buying_power: string;
  effective_buying_power: string;
  excess_equity?: string;
  equity: string;
  equity_securities?: string;
  id: string;
  initial_margin: string;
  intraday_adjustments?: string;
  intraday_trade_cashflow?: string;
  last_buying_power: string;
  jit_cash?: string;
  jit_cash_adjustment?: string;
  last_cash: string;
  last_daytrade_count: number;
  last_daytrading_buying_power: string;
  last_equity: string;
  last_initial_margin: string;
  last_long_market_value: string;
  last_maintenance_margin: string;
  last_regt_buying_power: string;
  last_short_market_value: string;
  long_market_value: string;
  maintenance_margin: string;
  marginable_mv?: string;
  memoposts?: string;
  multiplier: string;
  net_unsettled_prev?: string;
  net_unsettled_now?: string;
  non_marginable_buying_power: string;
  non_marginable_mv?: string;
  pattern_day_trader: boolean;
  pending_maintenance_margin?: string;
  pending_transfer_in: string;
  pending_transfer_out: string;
  portfolio_value: string;
  position_market_value: string;
  previous_close: Date;
  proj_settled_cash?: string;
  recent_cash_transfers?: string;
  regt_buying_power: string;
  settled_cash?: string;
  settled_cash_adjusted?: string;
  short_market_value: string;
  shorting_enabled: boolean;
  sma: string;
  status: string;
  trade_suspended_by_user: boolean;
  trading_blocked: boolean;
  transfers_blocked: boolean;
  unsettled_payable?: string;
  unsettled_receivable?: string;
}

export interface Activity {
  activity_type: string;
  id?: string;
  account_id: string;
  order_id?: string;
  date?: string;
  transaction_time?: moment.Moment;
  qty?: number;
  price?: number;
  side?: string;
  symbol?: string;
  net_amount?: number;
  status?: string;
  description?: string;
}

export interface Order {
  account_id: string;
  id: string;
  filled_avg_price: number;
  qty?: number;
  notional?: number;
  filled_qty: number;
  side: string;
  symbol: string;
  status: string;
  submitted_at: moment.Moment;
  created_at: moment.Moment;
  updated_at: moment.Moment;
  filled_at?: moment.Moment;
  expired_at?: moment.Moment;
  cancelled_at?: moment.Moment;
  failed_at?: moment.Moment;
  type: string;
  client_order_id: string;
  asset_id: string;
  asset_class: string;
  commission?: number;
  commission_bps?: number;
  time_in_force: string;
  limit_price?: number;
  stop_price?: number;
  order_type: string;
  formatted_updated_at?: string;
}

export interface Position {
  asset_id: string;
  avg_entry_price?: number;
  change_today: number;
  current_price: number;
  side: string;
  market_value: number;
  unrealized_pl: number;
  unrealized_plpc: number;
  symbol: string;
  qty: number;
}

export interface AggregatePosition {
  symbol: string;
  asset_type: string;
  closing_price: string;
  close_price_date: string;
  tradable: boolean;
  status: string;
  long_market_value: string;
  long_qty: string;
  num_accounts: string;
  short_market_value: string;
  short_qty: string;
}

export interface Asset {
  close_price_date: string;
  tradable: boolean;
  status: string;
}

export interface Stats {
  aum: number;
  active_accounts: number;
  active_accounts_change_week: string;
  active_accounts_change_last: number;
  orders_count_week: number;
  orders_count_week_change: number;
  accounts_created_30d: KeyValue;
}

export interface Transfer {
  id: string;
  account_id: string;
  type: string;
  status: string;
  currency: string;
  amount: string;
  direction: string;
  created_at: moment.Moment;
  updated_at: moment.Moment;
  expires_at: moment.Moment;
  additional_information?: string;
}

export interface Journal {
  id: string;
  entry_type: string;
  from_account: string;
  to_account: string;
  symbol: string;
  qty: number;
  price: number;
  status: string;
  settle_date?: moment.Moment;
  system_date?: moment.Moment;
  net_amount: number;
  description: string;
  currency: string;
}

export interface MemberAuthorization {
  user_id: string;
  correspondent: string;
  sandbox: boolean;
  role: string;
  status: string;
  created_at: moment.Moment;
  updated_at: moment.Moment;
}

export interface TeamMember {
  id: string;
  email: string;
  cognito_id?: string;
  name?: string;
  role?: string;
  correspondents?: MemberAuthorization[];
  created_at: moment.Moment;
  updated_at: moment.Moment;
  mfa_type?: string;
}

export interface ApiKey {
  id: string;
  secret?: string;
  status: string;
  created_at: moment.Moment;
}

export interface Document {
  id: string;
  type: string;
  date: string;
  account_id: string;
}

export interface Administrator {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  email: string;
  name: string;
}

export interface UploadedDocument {
  account_id: string;
  created_at: string;
  deleted_at: string | null;
  doc_sub_type: string;
  doc_type: string;
  id: string;
  last_notified_at: string | null;
  object_key: string;
  object_metadata: { [key: string]: string };
  object_url: string;
  requested_at: string | null;
  requested_by_admin_id: string | null;
  requested_by_admin?: Administrator;
  to_package: boolean;
  updated_at: string;
  uploaded_at: string;
  uploaded_by_admin_id: string | null;
  uploaded_by_admin?: Administrator;
}

export interface CorrespondentResponse {
  correspondent: string;
  name: string;
  setup: string;
  business_type?: BusinessType;
  status: string;
  bd_data?: BdData;
  crypto_enabled?: boolean;
  jnlc_daily_limit?: string;
  jnlc_transaction_limit?: string;
  firm_acct_daily_transfer_limit?: string;
  lct_currency?: string;
}

export interface IntercomUserHashResponse {
  intercom_id: string;
}

/*
 * REQUEST PARAMS
 */
export interface ListActivitiesParams {
  account_id?: string;
  page_token?: string;
  date?: string;
  until?: string;
  after?: string;
  direction?: "asc" | "desc";
  page_size?: number;
  activity_type?: string;
}

export interface OrdersParams {
  id?: string;
  status?: string;
  until?: string;
  limit?: number;
  after?: string;
  direction?: "desc" | "asc";
  nested?: boolean;
  symbols?: string[] | string;
  side?: string;
  qty_above?: number;
  qty_below?: number;
}

export interface PositionsQuery {
  symbols?: string[] | string;
  side?: string;
  until?: string;
  after?: string;
}

export interface TransfersParams {
  transfer_type?: string;
  limit?: number;
  offset?: number;
  direction?: string;
  updated_after?: string;
  updated_before?: string;
  amount_above?: number;
  amount_below?: number;
}

export type JournalStatus =
  | "pending"
  | "canceled"
  | "executed"
  | "rejected"
  | "all";
export type JournalType = "JNLC" | "JNLS";
export interface JournalsParams {
  after: string;
  before: string;
  entry_type: JournalType;
  status?: JournalStatus;
  to_account?: string;
  from_account?: string;
  id?: string;
}

export interface DocumentsParams {
  type: string;
  limit: number;
  offset: number;

  // YYYY-MM-DD
  start?: string;
  end?: string;
}

export interface DocumentDownloadParams {
  account_id: string;
  document_id: string;
  redirect?: boolean;
}

export interface RegistrationRequest {
  name?: string;
  email: string;
  cognito_id?: string;
  source: "brokerdash" | "mobile";
}

export interface ClaimInviteRequest {
  name: string;
}

export interface TeamMemberForm {
  id?: string;
  email: string;
  name: string;
  role: string;
}

export interface EditTeamMemberRequest {
  name?: string;
  role?: string;
}

export interface AddTeamMemberRequest {
  email: string;
  name: string;
  role: string;
}

export interface AccountsParams {
  status?: string;
  firmAccounts?: boolean;
  created_before?: string;
  created_after?: string;
  updated_before?: string;
  updated_after?: string;
  last_equity_below?: number;
  last_equity_above?: number;
  id?: string;
  account_number?: string;
  email_address?: string;
  query?: string;
}

export const activityTypes = [
  "JNLS",
  "DIVNRA",
  "INT",
  "CSW",
  "DIV",
  "JNLC",
  "CSD",
  "FILL",
  "FEE",
];

export enum BusinessType {
  BrokerDealer = "broker_dealer",
  NeoBank = "neobank",
  EstablishedFintech = "established_fintech",
  RegisteredInvestmentAdvisor = "registered_investment_advisor",
  SaaS = "saas",
  Other = "other",
}

export interface PatchCorrespondentRequest {
  business_type?: BusinessType;
  user_countries?: string[];
  name?: string;
  bd_data?: BdData;
  status?: string;
  entity_id?: string;
}

export interface UpdateCorrespondentRequest {
  business_type?: BusinessType;
  user_countries?: string[];
  name?: string;
  bd_data?: BdData;
  status?: string;
  entity_id?: string;
}

export interface PatchCorrespondentConfigRequest {
  crypto_enabled?: boolean;
  jnlc_daily_limit?: string;
  jnlc_transaction_limit?: string;
  firm_acct_daily_transfer_limit?: string;
}

export interface BdData {
  url?: string;
  user_countries?: string[];
  number_of_users?: string;
  app_type?: string;
  integration?: string;
  funding_plan?: string;
  funding_flow?: string;
  launch_guide_partner_type?: string;
  info_integration_plan?: string;
  info_customer_types?: string;
  info_geographic_markets?: string;
  target_investor?: string;
  waitlist?: string;
  business_name?: string;
  business_contact_number?: string;
  business_email?: string;
  business_address?: string;
  operation_contact?: string;
  finance_contact_email?: string;
  logo?: string;
}

export interface CreateEntityDetailsRequest {
  id?: string;
  entity_legal_name: string;
  entity_type: string;
  incorporation_date: string;
  country_of_registration: string;
  tax_id: string;
  tax_id_type: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  phone_number: string;
  dba_legal_entity_name: string;
  dba_entity_type: string;
  dba_incoporation_date?: string;
  dba_country_of_registration: string;
  dba_address: string;
  dba_relationship: string;
  dba_additional_info: string;
  fsp_registration_type: string;
  fsp_country_of_registration: string;
  fsp_registration_effective_date?: string;
  fsp_registration_number: string;
  fsp_additional_info: string;
  social_media_links: string[];
  kyb_status: string;
  correspondent?: string;
  email?: string;
  unit_number?: string;
  bd_data: BdData;
  registered_advisor?: boolean;
}

export interface PatchEntityDetailsRequest {
  entity_legal_name?: string;
  phone_number?: string;
  email?: string;
}

export interface EntityDetailsResponse extends CreateEntityDetailsRequest {
  id: string;
}

export interface CreateControlPersonRequest {
  entity_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  street_address: string;
  apartment_unit: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  phone_number: string;
  email: string;
  dob: string;
  tax_id: string;
  tax_id_type: string;
  kyc_status: string;
}

export interface CreateControlPersonResponse
  extends CreateControlPersonRequest {
  id: string;
}

export interface GetEntityAndControlPersonResponse {
  entity_details: CreateEntityDetailsRequest;
  control_person: CreateControlPersonRequest;
}

export interface GetAggregatePositionsResponse {
  positions: AggregatePosition[];
  symbol_info: Asset[];
}

/*
Zendesk Types
*/
export interface CreateZendeskTicketRequest {
  request: ZendeskRequest;
}

export interface CreateZendeskTicketResponse {
  id: number;
}

export interface UploadZendeskTicketAttachmentResponse {
  upload: { token: string };
}
export interface ZendeskRequest {
  comment: ZendeskComment;
  requester: ZendeskRequester;
  tags: string[];
  subject: string;
}

export interface ZendeskRequester {
  name: string;
  email: string;
}

export interface ZendeskComment {
  body: string;
  uploads?: string[];
}

export type CountryRiskRating =
  | "low"
  | "medium"
  | "high"
  | "restricted"
  | "prohibited";

export interface CountryInfo {
  [key: string]: {
    risk_rating: CountryRiskRating;
    full_name: string;
  };
}

export interface CountryRiskType {
  risk_rating: CountryRiskRating;
  full_name: string;
  iso_code: string;
}

export interface Report {
  order_id: string;
  execution_id: string;
  execution_time: string;
  external_id: string;
  trns_id: string;
  status: string;
  currency: string;
  amount: string;
  spread_bps: string;
  spread_share_rate: string;
  account_number: string;
  symbol: string;
  side: string;
  qty: string;
  price: string;
}

export interface ErrorResponse {
  code: number;
  message: string;
}

export interface CIDR {
  cidr: string;
}

export interface IPAllowlist {
  enabled: boolean;
  cidrs: string[];
}
