import { getCookie, setCookie } from "../globals/utils";
import * as Sentry from "@sentry/browser";
import { setAuthHeader } from "../api/api";
import axios from "axios";

const SECS_IN_DAY = 86400;

const paciam = `${process.env.REACT_APP_PACIAM_API_HOST}`;
export const paciamInstance = axios.create({ baseURL: paciam });

paciamInstance.defaults.headers["X-Request-Source"] = "brokerdash";

type PaciamSession = {
  access_token: string;
  refresh_token: string;
  refresh_expires_in: number;
  access_expires_in: number;
  account_source: string;
};

type PaciamChallengeResponse = {
  challenge: string;
  session: string;
  user_id: string;
  url?: string;
};

type VerifyResetCodeResponse = {
  token: string;
  expires_at: string;
};

type PaciamLoginResponse = PaciamSession & PaciamChallengeResponse;

export const login = async (
  username: string,
  password: string
): Promise<PaciamLoginResponse> => {
  const email = username.toLowerCase();
  const resp = await paciamInstance.post("/authenticate", {
    email,
    s: password,
  });

  Sentry.setUser({ email });
  const data: PaciamLoginResponse = resp.data;

  if (data.access_token) {
    parsePaciamSession(data);
    setAuthHeader();
  }

  return data;
};

export const logout = async (): Promise<void> => {
  await paciamInstance.post("/logout");
};

export const getPaciamJwt = async (): Promise<string> => {
  const paciamAccessToken = getCookie("paciamAccessToken");
  if (paciamAccessToken) return paciamAccessToken;

  const paciamRefreshToken = getCookie("paciamRefreshToken");

  const resp = await paciamInstance.post("/authenticate/refresh", {
    refresh_token: paciamRefreshToken,
  });
  const data: PaciamSession = resp.data;
  parsePaciamSession(data);

  return resp.data.access_token;
};

export const refreshPaciamToken = async (
  refreshToken: string
): Promise<void> => {
  const resp = await paciamInstance.post("/authenticate/refresh", {
    refresh_token: refreshToken,
  });
  const data: PaciamSession = resp.data;
  parsePaciamSession(data);
};

export const confirmSMSLogin = async (
  code: string,
  sessId: string,
  userId: string
): Promise<void> => {
  const resp = await paciamInstance.post("/authenticate/challenge/complete", {
    code,
    session_id: sessId,
    user_id: userId,
  });

  const data: PaciamLoginResponse = resp.data;
  if (data.access_token) {
    parsePaciamSession(data);
    setAuthHeader();
  }
};

export const register = async ({
  email,
  password,
  name,
  signUpRole,
}: {
  email: string;
  password: string;
  name: string;
  signUpRole?: string;
}): Promise<void> => {
  await paciamInstance.post("/authenticate/user", {
    email: email.toLowerCase(),
    name,
    s: password,
    source: "brokerdash",
    sign_up_role: signUpRole,
  });
};

export const confirmSignUp = async (
  email: string,
  code: string,
  name: string,
  password: string
): Promise<void> => {
  await paciamInstance.post("/authenticate/user/verify-email", {
    email: email.toLowerCase(),
    code,
  });

  if (password) {
    await login(email, password);
    console.info("Registration completed");
  }
};

// MFA setup methods - these require Auth headers as opposed to the rest of the functions
// since they are to be used in the context of an already authenticated user.

export const enableMfa = async (): Promise<PaciamChallengeResponse> => {
  paciamInstance.defaults.headers["Authorization"] = `Bearer ${getCookie(
    "paciamAccessToken"
  )}`;
  const resp = await paciamInstance.post("/mfa", {
    mfa_type: "totp",
  });
  const data: PaciamChallengeResponse = resp.data;
  return data;
};

export const verifyMfa = async (code: string): Promise<void> => {
  paciamInstance.defaults.headers["Authorization"] = `Bearer ${getCookie(
    "paciamAccessToken"
  )}`;
  const resp = await paciamInstance.post("/mfa/verify", {
    code,
    mfa_type: "totp",
  });
  return resp.data;
};

// Password management

export const forgotPassword = async (email: string): Promise<void> => {
  const resp = await paciamInstance.post("/authenticate/user/reset-code", {
    email: email.toLowerCase(),
  });
  return resp.data;
};

export const verifyResetCode = async (
  email: string,
  code: string
): Promise<VerifyResetCodeResponse> => {
  const resp = await paciamInstance.post(
    "/authenticate/user/reset/verify-reset",
    {
      email: email.toLowerCase(),
      code,
    }
  );
  return resp.data;
};

export const resetSecret = async (token: string, s: string): Promise<void> => {
  const resp = await paciamInstance.post("/authenticate/user/reset", {
    token,
    s,
  });
  return resp.data;
};

const parsePaciamSession = (sess: PaciamSession) => {
  const {
    access_token,
    access_expires_in,
    refresh_token,
    refresh_expires_in,
  } = sess;
  setCookie("paciamAccessToken", access_token, access_expires_in / SECS_IN_DAY);
  setCookie(
    "paciamRefreshToken",
    refresh_token,
    refresh_expires_in / SECS_IN_DAY
  );
};
